<template>
  <div class="setting-dashboard">
    <SettingHeader>Dashboard</SettingHeader>
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="Account Info" force-render>
        <div class="dash-row">
          <div class="dash-col-left">
            <Account />
            <div class="keyword-noti-wrapper">
              <a-card title="Keyword Notification Setting">
                <template #extra>
                  <div class="add-keyword" @click="onAddKeyword"><plus-outlined /> Add</div>
                </template>
                <div v-if="loading" class="load-wrapper">
                  <a-skeleton :loading="loading" active />
                </div>

                <div v-if="!loading && keywordList && keywordList.length > 0">
                  <TableSetting
                    v-if="keywordList && keywordList.length > 0"
                    :type="'dashboard'"
                    :rowKey="'id'"
                    :isRowSelection="true"
                    :isTimeFromNow="true"
                    :isActionDropDown="true"
                    :tableData="keywordList"
                    :columns_props="columnConfig"
                    :actions="actions"
                    :pagination="{
                      showQuickJumper: true,
                      total: keywordList.length,
                      showSizeChanger: false,
                      showTotal: true,
                    }"
                    @toggleAction="onToggleAction"
                  />
                </div>
                <div v-if="!loading && keywordList && keywordList.length <= 0">
                  <NoDataAspect :title="'No Keyword Added'"> </NoDataAspect>
                </div>
              </a-card>
            </div>
          </div>
          <div class="dash-col-right">
            <a-card>
              <template #title>
                Monthly Performance : <span style="color: #9299b8"> {{ monthly }}</span>
              </template>
              <DataUsage />
              <TopKeyword />
            </a-card>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Social Media Account Group">
        <GroupList />
      </a-tab-pane>
    </a-tabs>

    <AddKeywordModal
      :visible="addKeywordVisible"
      :mode="modalMode"
      :keywordObj="keywordObj"
      @reloadKeywordList="reloadKeywordList"
      @close="toggleAddKeywordModal(false)"
    />

    <ConfirmDelete :visible="confirmVisible" @close="confirmVisible = false" @confirm="deleteKeyword">
      Are you sure to delete this keyword notification ?
    </ConfirmDelete>
  </div>
</template>

<script>
import { ref, onMounted, computed, reactive } from 'vue';
import api from '@/services/api';
import { PlusOutlined } from '@ant-design/icons-vue';
import helper from '@/services/helper';
import { useRoute } from 'vue-router';
import SettingHeader from './SettingHeader.vue';
import Account from './AccountInfo/Account.vue';
import DataUsage from './AccountInfo/DataUsage.vue';
import GroupList from './SocialMediaAccountGroup/GroupList.vue';
import AddKeywordModal from './AccountInfo/AddKeywordModal.vue';
import TableSetting from '@/components/Table/TableSetting.vue';
import TopKeyword from './AccountInfo/TopKeyword.vue';
import ConfirmDelete from '@/components/ConfirmDelete.vue';

import NoDataAspect from '@/components//Error/NoDataAspect.vue';
import { notification } from 'ant-design-vue';
import moment from 'moment';

export default {
  components: {
    SettingHeader,
    Account,
    DataUsage,
    GroupList,
    PlusOutlined,
    AddKeywordModal,
    TableSetting,
    TopKeyword,
    ConfirmDelete,
    NoDataAspect,
  },
  setup() {
    const route = computed(() => useRoute());
    const activeKey = ref('1');
    let loading = ref(true);
    let addKeywordVisible = ref(false);
    let keywordList = ref([]);
    const confirmVisible = ref(false);
    let modalMode = ref();
    const deletingItem = reactive({
      keyword: '',
      isMultiple: false,
      keywordList: [],
    });

    const monthly = computed(() => {
      return moment().format('MMM YYYY');
    });

    // for add keyword modal
    const onAddKeyword = () => {
      modalMode.value = 'add';
      addKeywordVisible.value = true;
    };

    const toggleAddKeywordModal = (state) => {
      addKeywordVisible.value = state;
    };

    const reloadKeywordList = () => {
      addKeywordVisible.value = false;
      getAlertKeyword();
    };

    // for Keyword Notification Table
    const columnConfig = [
      {
        title: 'Keyword',
        dataIndex: 'id',
        key: 'id',
        sorter: helper.getTableSortFn('string', 'id'),
        width: 120,
      },
      {
        title: 'Sentiment',
        dataIndex: 'sentiment',
        key: 'sentiment',
        slots: { customRender: 'sentiment' },
        sorter: helper.getTableSortFn('number', 'sentiment'),
        width: 80,
      },
      {
        title: 'Updated at',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        slots: { customRender: 'updated_at' },
        sorter: helper.getTableSortFn('date-x', 'updatedAt'),
        defaultSortOrder: 'descend',
        width: 100,
      },
      {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' },
        width: 70,
      },
    ];

    const actions = [
      { name: 'Edit', value: 'edit', icon: 'edit' },
      { name: 'Delete', value: 'delete', icon: 'trash-2' },
    ];

    const getAlertKeyword = async () => {
      loading.value = true;
      const result = await api.getAlertKeyword().catch(() => {});
      if (result && result.message) {
        keywordList.value = result.message;
        for (let item in keywordList.value) {
          if (keywordList.value[item].id.charAt(0) === '#') {
            keywordList.value[item].id = `${keywordList.value[item].id}`;
            keywordList.value[item].type = 'hashtag';
          } else if (keywordList.value[item].id.indexOf('username:') === 0) {
            keywordList.value[item].id = `@${keywordList.value[item].id.slice(9)}`;
            keywordList.value[item].type = 'username';
          } else if (keywordList.value[item].id.charAt(0) === '@') {
            keywordList.value[item].id = `${keywordList.value[item].id}`;
            keywordList.value[item].type = 'username';
          } else {
            keywordList.value[item].id = `${keywordList.value[item].id}`;
            keywordList.value[item].type = 'keyword';
          }
          keywordList.value[item].sentiment = keywordList.value[item].value;
        }
      }
      loading.value = false;
    };

    let keywordObj = ref();

    const onToggleAction = (actionObj) => {
      const { action, record } = actionObj;
      if (action === 'edit') {
        modalMode.value = 'edit';
        addKeywordVisible.value = true;
        keywordObj.value = record;
      } else if (action === 'delete') {
        confirmVisible.value = true;
        keywordObj.value = record;
      } else if (action === 'deleteMultiple') {
        let lists = [];
        for (let keyword of record) {
          lists.push(convertKeywordForRemove(keyword));
        }
        Object.assign(deletingItem, {
          keywordList: lists,
          isMultiple: true,
        });
        confirmVisible.value = true;
      }
    };

    const deleteKeyword = async () => {
      let result = {};
      if (deletingItem.isMultiple) {
        result = await api.bulkAlertKeywordLists(deletingItem.keywordList).catch(() => {
          notification.error({
            message: 'Error',
            description: 'Keyword remove failed.',
          });
        });
      } else {
        const keywordId = convertKeywordForRemove(keywordObj.value);
        result = await api.removeAlertKeyword(keywordId).catch(() => {
          notification.error({
            message: 'Error',
            description: 'Keyword remove failed.',
          });
        });
      }

      if (result) {
        notification.success({
          message: 'Success',
          description: 'Keyword removed.',
        });
        getAlertKeyword();
      }

      Object.assign(deletingItem, {
        keyword: '',
        isMultiple: false,
        keywordList: [],
      });
      confirmVisible.value = false;
    };

    const convertKeywordForRemove = (obj) => {
      const { type, id } = obj;
      if (type === 'hashtag') {
        if (id.charAt(0) !== '#') {
          return `#${id}`;
        }

        return id;
      } else if (type === 'username') {
        if (id.charAt(0) === '@') {
          return `username:${id.slice(1)}`;
        }
        return `username:${id}`;
      } else if (type === 'keyword') {
        if (id.charAt(0) === '@' || id.charAt(0) === '#') {
          return id.slice(1);
        }
        return id;
      }
      return id;
    };

    onMounted(() => {
      if (route.value.query.activeKey) {
        activeKey.value = route.value.query.activeKey;
      }
      getAlertKeyword();
    });

    return {
      activeKey,
      loading,
      onAddKeyword,
      addKeywordVisible,
      toggleAddKeywordModal,
      columnConfig,
      actions,
      keywordList,
      onToggleAction,
      confirmVisible,
      deleteKeyword,
      modalMode,
      keywordObj,
      reloadKeywordList,
      monthly,
    };
  },
};
</script>

<style lang="scss" scoped>
.setting-dashboard {
  padding: 30px;
  overflow: auto;
  height: 100%;
  width: 100%;
  .dash-row {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 15px;
    @media screen and (max-width: 500px) {
      display: block;
    }
    .dash-col-left {
      flex: 2;

      .keyword-noti-wrapper {
        .add-keyword {
          background: #3371ff;
          border-radius: 4px;
          padding: 9px 20px;
          color: #fff;
          cursor: pointer;
        }
      }
    }
    .dash-col-right {
      flex: 1;
      .ant-card-head-title span {
        margin-left: 0px;
        font-size: initial;
        font-weight: 400;
      }
    }
    @media only screen and (max-width: 767px) {
      .dash-col-left,
      .dash-col-right {
        flex: 0 0 100%;
      }
    }
  }
}
</style>
