<template>
  <a-card class="dashboard-card">
    <a-row type="flex" justify="space-between">
      <a-col>
        <a-row type="flex" :gutter="20" align="middle">
          <a-col>
            <Avatar class="avatar-desktop" :src="accountProfileImage" :username="brand" :size="100"></Avatar>
            <Avatar class="avatar-mobile" :src="accountProfileImage" :username="brand" :size="50"></Avatar>
          </a-col>
          <a-col>
            <div class="account-name">{{ account.name }}</div>
            <div class="account-since">Since {{ since }}</div>
          </a-col>
        </a-row>
      </a-col>
      <!-- <a-col align="right">
        <a-button type="default" class="ant-default-custom">
          <a-row :gutter="5" type="flex">
            <a-col :span="4"><FeatherIcon type="upload" color="#9299b8" :size="12" /></a-col>
            <a-col :sm="20" :xs="0"><span class="ml-2">Export Settings</span></a-col>
          </a-row>
        </a-button>
      </a-col> -->
    </a-row>
    <hr height="1px" />
    <a-row class="stats-wrapper">
      <div v-for="(stat, index) in statsData" :key="index" :span="span" class="stat-item">
        <a-row type="flex" :gutter="10">
          <a-col
            ><img :key="index" :src="require(`../../../assets/images/icon/${stat.svg}`)" class="dashboard-icon"
          /></a-col>
          <a-col>
            <a-skeleton :loading="loading" :paragraph="false">
              <h2 class="m-0 dashboard-value">{{ comma(stat.count) }}</h2>
            </a-skeleton>
            <div class="label-stat">{{ stat.label }}</div>
          </a-col>
        </a-row>
      </div>
    </a-row>
  </a-card>
</template>

<script>
import moment from 'moment';
import { computed, ref, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import helper from '@/services/helper';
import api from '@/services/api';
import Avatar from '@/components/Avatar.vue';
export default {
  name: 'Account',
  components: {
    Avatar,
  },
  props: {
    // account: {
    //   required: true,
    //   type: Object,
    //   default: () => {
    //     return {};
    //   },
    // },
    // stats: {
    //   type: Array,
    //   required: true,
    //   default: () => {
    //     return [];
    //   },
    // },
  },
  setup() {
    const store = useStore();

    const brand = computed(() => store.state.account.brand);
    const accountProfileImage = computed(() => store.state.account.userAccount.accountImageURL);
    const stats = ref([
      {
        count: store.state.account.userAccount.visibleSources.length,
        label: 'Sources',
        svg: 'icon-sources.svg',
      },
      {
        count: 0,
        label: 'Keywords',
        svg: 'icon-keywords.svg',
      },
      {
        count: 0,
        label: 'Active users',
        svg: 'icon-user-accounts.svg',
      },
    ]);
    let loading = ref(false);

    onMounted(() => {
      init();
    });

    const user = reactive({
      keywordCount: 0,
      userCount: 0,
    });

    const init = async () => {
      loading.value = true;
      const result = await api.getAccountStatSummary().catch(() => {});
      if (result && result.message) {
        const { keywordCount, userCount } = result.message;
        user.keywordCount = keywordCount;
        user.userCount = userCount;
      }
      loading.value = false;
    };

    const statsData = computed(() => {
      if (stats.value && stats.value.length > 0) {
        for (let stat of stats.value) {
          if (stat.label === 'Keywords') {
            stat.count = user.keywordCount;
          } else if (stat.label === 'Active users') {
            stat.count = user.userCount;
          }
        }
      }
      return stats.value;
    });

    const since = computed(() => {
      return moment(account.value.created_at).format('MMMM YYYY');
    });

    const span = computed(() => {
      return 24 / stats.value.length;
    });

    const account = computed(() => {
      return {
        name: store.state.account.brand,
        color: store.state.account.userAccount.color,
        created_at: store.state.account.userInfo.createdAt ? store.state.account.userInfo.createdAt : 0, // TO-DO will be replace
      };
    });

    return {
      since,
      span,
      comma: helper.numeral,
      account,
      user,
      statsData,
      loading,
      accountProfileImage,
      brand,
    };
  },
};
</script>

<style lang="scss" scoped>
.account-name {
  font-size: 20px;
}

.account-since {
  color: #9299b8;
}

hr {
  margin-top: 25px;
  margin-bottom: 25px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.ant-default-custom {
  border-width: 1px;
  border-color: #f1f2f6;
}

.stats-wrapper {
  column-gap: 20%;
  row-gap: 24px !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .stat-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .label-stat {
    color: #868eae;
  }
}

.avatar-desktop {
  @media screen and (max-width: 500px) {
    display: none !important;
  }
}

.avatar-mobile {
  display: none !important;

  @media screen and (max-width: 500px) {
    display: flex !important;
  }
}

@media screen and (max-width: 500px) {
  .dashboard-icon {
    width: 40px;
    height: 40px;
  }

  .dashboard-value {
    font-size: 1rem;
  }

  .label-stat {
    font-size: 12px;
  }
}
</style>
